import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import * as Styled from "./About.styled";
import { useContext } from "react";
import { ThemeContext } from "../../App";
import { Box, Card, CardContent, useMediaQuery } from "@mui/material";
import Hunting from "../../assets/hunting.jpg";

export const About = () => {
  const mobile = useMediaQuery("(min-width:800px)");
  const themeContext = useContext(ThemeContext);

  return (
    <PageWrapper
      childWrapperStyled={{ paddingLeft: 0, paddingRight: 0 }}
      useLightMode={!themeContext.darkMode}
    >
      <Box paddingX={"10%"}>
        <Styled.MainText darkmode={!themeContext.darkMode}>
          About Us
        </Styled.MainText>
      </Box>

      <div
        style={{
          backgroundImage: `url(${Hunting})`,
          backgroundSize: "cover",
          width: "100%",
          height: "auto",
          paddingTop: 70,
          paddingBottom: 70,
          backgroundPosition: "bottom",
          marginTop: 30,
        }}
      >
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          paddingX={"10%"}
        >
          <Card
            sx={{
              maxWidth: !mobile ? "100%" : "80%",
              backgroundColor: themeContext.darkMode
                ? "rgba(255, 255, 255, 0.6)"
                : "rgba(14, 2, 70, 0.6)",
            }}
          >
            <CardContent>
              <Styled.SubText darkmode={!themeContext.darkMode}>
                Located in the Pacific Northwest, ReSygnal is a team of
                professional designers, engineers, and software architects. Our
                diverse background in software helps us create a wide variety of
                solutions.
              </Styled.SubText>
            </CardContent>
          </Card>
          <Card
            sx={{
              marginTop: "70px",
              marginLeft: "auto",
              maxWidth: !mobile ? "100%" : "80%",
              backgroundColor: themeContext.darkMode
                ? "rgba(255, 255, 255, 0.6)"
                : "rgba(14, 2, 70, 0.6)",
            }}
          >
            <CardContent>
              <Styled.SubText darkmode={!themeContext.darkMode}>
                We understand the importance of having a reliable and efficient
                software system that meets your business needs. We specialize in
                creating custom software solutions that are tailored to your
                business requirements, ensuring that your organization operates
                smoothly and efficiently.
              </Styled.SubText>
            </CardContent>
          </Card>
          <Card
            sx={{
              marginTop: "70px",
              maxWidth: !mobile ? "100%" : "80%",
              backgroundColor: themeContext.darkMode
                ? "rgba(255, 255, 255, 0.6)"
                : "rgba(14, 2, 70, 0.6)",
            }}
          >
            <CardContent>
              <Styled.SubText darkmode={!themeContext.darkMode}>
                Our team of experienced developers and designers work closely
                with you to understand your business goals and requirements, and
                then develop solutions that are tailored to meet those needs.
                Whether you need a simple website, a mobile application, or a
                complex enterprise software system, we can deliver quality
                products that meet your specifications.
              </Styled.SubText>
            </CardContent>
          </Card>
        </Box>
      </div>
    </PageWrapper>
  );
};

import { Box, Stack, Typography } from "@mui/material";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import { useContext } from "react";
import { ThemeContext } from "../../App";
import * as Styled from "../landing/Landing.styled";
import NownWhite from "../../assets/nown-logos/WhiteColor.svg";
import NownBlack from "../../assets/nown-logos/BlackColor.svg";
import Phone from "../../assets/nown-mockups/phone.png";
import Mac from "../../assets/nown-mockups/mac.png";
import PhoneDark from "../../assets/nown-mockups/phone-dark.png";
import MacDark from "../../assets/nown-mockups/mac-dark.png";

export const Products = () => {
  const themeContext = useContext(ThemeContext);

  return (
    <PageWrapper useLightMode={!themeContext.darkMode}>
      <Styled.MainText darkmode={!themeContext.darkMode}>
        Meet Our Products
      </Styled.MainText>
      <Box display="flex" justifyContent="space-between">
        <Stack>
          <img
            style={{ width: 150, marginTop: 30 }}
            src={!themeContext.darkMode ? NownWhite : NownBlack}
          />
          <Typography
            color="grey"
            variant="h6"
            sx={{
              fontFamily: "'Ubuntu', sans-serif !important",
              marginBottom: "15px",
            }}
          >
            /noun/
          </Typography>
        </Stack>
        <Stack my="auto">
          <a rel="noreferrer" href="https://nown.resygnal.com" target="_blank">
            <Styled.ButtonSolid darkmode={!themeContext.darkMode}>
              Visit Nown
            </Styled.ButtonSolid>
          </a>
        </Stack>
      </Box>
      <Styled.SubText darkmode={!themeContext.darkMode}>
        Nown is a versatile software tool designed to track people, places, and
        things efficiently. Whether you're managing a team, monitoring
        locations, or keeping tabs on important items, Nown offers seamless
        tracking capabilities to ensure you stay organized and informed. With
        user-friendly features, real-time updates, and a growing app library for
        new integrations, Nown is your go-to solution for comprehensive tracking
        and management.
      </Styled.SubText>
      <Box
        justifyContent="space-around"
        display="flex"
        marginTop={7}
        paddingBottom={5}
        gap={10}
        flexWrap="wrap"
      >
        <img
          style={{ height: "50vw", maxHeight: 400 }}
          src={!themeContext.darkMode ? MacDark : Mac}
        />
        <img
          style={{ maxWidth: 200 }}
          src={!themeContext.darkMode ? PhoneDark : Phone}
        />
      </Box>
    </PageWrapper>
  );
};
